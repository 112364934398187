* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Arial', sans-serif;
    color: #333;
    line-height: 1.6;
}

/* Cabeçalho e navegação */
header {
    background-color: #333;
    padding: 20px 0;
    color: #fff;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.logo {
    font-size: 24px;
    font-weight: bold;
}

.nav-links {
    list-style: none;
}

.nav-links li {
    display: inline;
    margin-left: 20px;
}

.nav-links a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

.nav-links a:hover {
    text-decoration: underline;
}

/* Seção hero */
.hero {
    background-color: #f4f4f4;
    text-align: center;
    padding: 100px 20px;
}

.hero h1 {
    font-size: 48px;
    margin-bottom: 20px;
}

.hero p {
    font-size: 18px;
    margin-bottom: 40px;
}

.cta-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    margin-right: 2rem ;
}

.cta-button:hover {
    background-color: #555;
}

/* Seção Sobre */
.about {
    padding: 60px 20px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.about h2 {
    font-size: 36px;
    margin-bottom: 20px;
}

.about p {
    font-size: 18px;
    line-height: 1.8;
}

/* Rodapé */
footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 20px 0;
    position: relative;
    bottom: 0;
    width: 100%;
}

/* Responsividade */
@media (max-width: 768px) {
    .hero h1 {
        font-size: 36px;
    }

    .about h2 {
        font-size: 28px;
    }

    .nav-links li {
        display: block;
        margin: 10px 0;
        text-align: center;
    }
}
